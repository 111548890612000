@import "~common/stylesheet/index";

.category {
  justify-content: space-between;

  .icon {
    margin-left: 4px;
  }
}

.expandable_list_item {
  background-color: var(--backgroundColor);
  border-bottom: 1px solid var(--backgroundColor);
}
