@import "~common/stylesheet/index";

.divider {
  position: relative;
  z-index: 97;

  &:after {
    position: absolute;
    background-color: $theme-light;
    content: '';
  }

  &.horizontal {
    width: 7px;
    margin: 0 -3px;
    cursor: ew-resize;

    &:after {
      top: 0;
      bottom: 0;
      left: 3px;
      width: 1px;
    }
  }

  &.vertical {
    height: 7px;
    margin: -3px 0;
    cursor: ns-resize;

    &:after {
      left: 0;
      right: 0;
      top: 3px;
      height: 1px;
    }
  }
}
