@import "~common/stylesheet/index";

.resizable_container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 0;
  min-height: 0;

  &.horizontal {
    flex-direction: row;
  }

  .wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;

    &.horizontal {
      flex-direction: row;
    }
  }
}
