@import "~common/stylesheet/index";

.progress_bar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: var(--colorFont);
  cursor: pointer;
  pointer-events: auto;
  background-color: var(--colorOverlay);
  border-radius: 4px;

  > * {
    pointer-events: none;
  }

  .active {
    position: absolute;
    height: 100%;
    border-radius: 4px;
    left: 0;
    background-color: var(--colorHighlight);
  }

  .label {
    position: absolute;
    color: var(--colorFont);

    .current {
      font-weight: bold;
    }
  }
}
