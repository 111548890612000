$theme-dark: var(--backgroundColor, #ffffff);
$theme-normal: var(--themeNormal, #f5f5f5);
$theme-light: var(--themeLight, #e0e0e0);
$color-font: var(--colorFont, #333333);
$color-shadow: var(--colorShadow, rgba(#000000, .2));
$color-overlay: var(--colorOverlay, rgba(#ffffff, .1));
$color-alert: var(--colorAlert, #f3bd58);
$color-selected: var(--colorSelected, #2962ff);
$color-patched: var(--colorPatched, #c51162);
$color-highlight: var(--colorHighlight, #29d);
$color-active: var(--colorActive, #00e676);

:export {
  backgroundColor: $theme-dark;
  themeNormal: $theme-normal;
  themeLight: $theme-light;
  colorFont: $color-font;
  colorShadow: $color-shadow;
  colorOverlay: $color-overlay;
  colorAlert: $color-alert;
  colorSelected: $color-selected;
  colorPatched: $color-patched;
  colorHighlight: $color-highlight;
  colorActive: $color-active;
}
