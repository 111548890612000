@import "~common/stylesheet/index";

.array_2d {
  flex-shrink: 0;
  display: table;
  border-collapse: separate;
  border-spacing: 2px;
  font-family: 'Consolas', 'Monaco', monospace;
  
  .row {
    display: table-row;
    height: 32px;
  
    .col {
      display: table-cell;
      text-align: center;
      min-width: 32px;
      background-color: #2b2b2b;
      border: 1px solid #3a3a3a;
      transition: background-color 0.2s ease, border-color 0.2s ease;
      
      .value {
        font-size: 13px;
        color: #e0e0e0;
        font-weight: 500;
      }

      &:hover:not(.index) {
        background-color: #353535;
        border-color: #4a4a4a;
      }

      &.selected {
        background-color: #2c5282;
        border: 2px solid #4299e1;
      }

      &.patched {
        background-color: #2d3748;
        border: 2px solid #48bb78;
      }
      
      &.index {
        background: none;
        border: none;
        color: #718096;
        font-size: 11px;
        opacity: 0.8;
      }
    }
  }
}
