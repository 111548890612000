@import "~common/stylesheet/index";

html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  font-family: $font-family-normal;
  -webkit-font-smoothing: subpixel-antialiased;
  user-select: none;
  color: $color-font;
  font-size: $font-size-normal;
}

a {
  text-decoration: none;
  color: inherit;
}

* {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  background: none;
  border: none;
  outline: none;
  min-width: 0;
  margin: 0;
  padding: 0;
  line-height: 1.15;
}
