@import "~common/stylesheet/index";

.theme_selector {
  background: var(--themeNormal);
  color: var(--colorFont);
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  
  &:hover {
    background: var(--themeLight);
  }
  
  option {
    background: var(--backgroundColor);
  }
}