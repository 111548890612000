@import "~common/stylesheet/index";

.player {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 100%;
  padding: 0 12px;
  border-radius: 4px;

  .button {
    height: 30px;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 14px;
    transition: all 0.2s ease;
    background-color: var(--colorOverlay);
    color: var(--colorFont);
    
    &:hover {
      background-color: var(--colorShadow);
    }

    &:active {
      transform: scale(0.95);
    }

    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .progress_bar {
    width: 180px;
    height: 30px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .speed {
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 14px;

    .range {
      position: relative;
      height: 16px;
      width: 80px;
      margin-left: 8px;

      .range_label_container {
        display: none;
      }

      .range_track {
        position: relative;
        top: 50%;
        height: 4px;
        margin-top: -2px;
        background-color: var(--colorHighlight);
        border-radius: 2px;
        cursor: pointer;
      }

      .range_slider {
        top: 50%;
        width: 12px;
        height: 12px;
        margin-left: -6px;
        margin-top: -5px;
        appearance: none;
        background-color: #fff;
        border-radius: 50%;
        cursor: pointer;
        position: absolute;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        transition: transform 0.2s ease;

        &:hover {
          transform: scale(1.2);
        }

        &:active {
          transform: scale(0.9);
        }
      }
    }
  }
}
