@import "~common/stylesheet/index";

.toast_container {
  display: flex;
  flex-direction: column-reverse;
  padding: 16px;
  pointer-events: none;
}

.toast {
  width: 280px;
  border: 1px solid;
  border-radius: 4px;
  padding: 16px;
  margin: 8px;
  white-space: pre-wrap;
  pointer-events: auto;
  font-family: $font-family-monospace;

  &.success {
    border-color: rgb(0, 150, 0);
    background-color: rgba(0, 120, 0, .8);
  }

  &.error {
    border-color: rgb(150, 0, 0);
    background-color: rgba(120, 0, 0, .8);
  }
}
