@import "~common/stylesheet/index";

.graph {
  flex: 1;
  align-self: stretch;

  .node {
    .circle {
      fill: $theme-light;
      stroke: $color-font;
      stroke-width: 1;
    }

    .id {
      fill: $color-font;
      alignment-baseline: central;
      text-anchor: middle;
    }

    .weight {
      fill: white;
      font-weight: bold;
      alignment-baseline: central;
      text-anchor: left;
    }

    &.selected {
      .circle {
        fill: $color-selected;
        stroke: $color-selected;
      }
    }

    &.visited {
      .circle {
        fill: $color-patched;
        stroke: $color-patched;
      }
    }
  }

  .edge {
    .line {
      stroke: $color-font;
      stroke-width: 2;

      &.directed {
        marker-end: url(#markerArrow);
      }
    }

    .weight {
      fill: $color-font;
      alignment-baseline: baseline;
      text-anchor: middle;
    }

    &.selected {
      .line {
        stroke: $color-selected;

        &.directed {
          marker-end: url(#markerArrowSelected);
        }
      }

      .weight {
        fill: $color-selected;
      }
    }

    &.visited {
      .line {
        stroke: $color-patched;

        &.directed {
          marker-end: url(#markerArrowVisited);
        }
      }

      .weight {
        fill: $color-patched;
      }
    }
  }

  .arrow {
    fill: $color-font;

    &.selected {
      fill: $color-selected;
    }

    &.visited {
      fill: $color-patched;
    }
  }
}
